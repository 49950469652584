<template>
  <v-app light id="app" :class="getClassApp">
    <div v-if="(loggedIn && $route.name != 'login') || loggingOut">
      <sidebar-left />
      <transition name="fade">
        <app-header @logOut="logOut()" :loggingOut="loggingOut" />
      </transition>

      <v-main>
        <v-container fluid>
          <transition name="fade">
            <router-view :key="$route.params.id"></router-view>
          </transition>
        </v-container>
      </v-main>

      <app-versionupdatesnackbars />

      <app-onboarding ref="onboarding" />
      <app-reportingerror />
      <app-footer />
    </div>

    <v-container
      v-else-if="!loggedIn && $route.name == 'login'"
      class="max-w-login-container d-flex justify-center align-center"
      fill-height
      fluid
    >
      <transition name="fade">
        <v-row no-gutters>
          <v-col cols="12">
            <login></login>
          </v-col>
        </v-row>
      </transition>
    </v-container>

    <v-container
      v-else-if="!loggedIn"
      :class="
        $route.name !== 'requestaccess'
          ? 'max-w-login-container'
          : 'max-w-requare-access-container'
      "
      fill-height
      fluid
    >
      <v-row align="center" justify="center">
        <v-col>
          <transition name="fade">
            <router-view :key="$route.params.id"></router-view>
          </transition>
        </v-col>
      </v-row>
    </v-container>
    <sidebar-right ref="sidebarRight" />
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      userIsAdministrator: this.$auth.userIsAdministrator(),
      userIsUserManager: this.$auth.userIsUserManager(),
      loggedIn: this.$auth.loggedIn(),
      loggingOut: false,
      websiteName: this.$config.websiteName,
    };
  },
  created() {
    this.$auth.onChange = (loggedIn) => {
      this.loggedIn = loggedIn;
      this.userIsAdministrator = this.$auth.userIsAdministrator();
      this.userIsUserManager = this.$auth.userIsUserManager();

      if (this.loggedIn) {
        this.$store.dispatch("getOnboardingItems");
        this.getWebsites();
      }
    };

    if (this.loggedIn) {
      this.$store.dispatch("getOnboardingItems");
      this.getWebsites();
      if (this.userIsAdministrator) {
        this.$request.get("/update-required", null, (result) => {
          //view orange snackbar for update
          if (result.data == true) {
            this.$store.dispatch("setUpdateSnackbar", true);
          } else if (result.message) {
            const toaster = {
              message: result.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      }
    }
    document.title = this.$config.websiteName;
  },

  computed: {
    ...mapGetters({
      websites: "allWebsites",
      selectedWebsite: "selectedWebsite",
      isReporting: "isReporting",
      updateSnackbar: "updateSnackbar",
      updateVersionSnackbar: "isSuccessfullyUpdated",
      isNavigating: "isNavigating",
    }),

    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },

    pageTitle() {
      return document.title;
    },
    getClassApp() {
      return this.$route.name == "login" ||
        this.$route.name == "forgotPassword" ||
        this.$route.name == "resetPassword" ||
        this.$route.name == "requestaccess" ||
        this.$route.name == "activateAccount"
        ? "appColor"
        : "appBackgroundColorMain";
    },
  },
  methods: {
    logOut() {
      this.loggingOut = true;
      this.$auth.logOut((res) => {
        this.loggingOut = false;

        if (!res.success) {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        } else {
          this.$router.push({ name: "login" });
          this.$router.go(1);
        }
      });
    },

    getWebsites() {
      if (this.websites.length == 0) {
        this.$request.get("/websites", null, (res) => {
          if (res.success) {
            this.$store.dispatch("setAllWebsites", res.data);
            this.$store.dispatch("setSelectedWebsite", this.websites[0]);
            if (
              this.$route.path == "/" &&
              this.loggedIn &&
              !this.isNavigating
            ) {
              this.$router.replace({
                name: "editPage",
                params: { id: this.selectedWebsite.DashboardPageId.$oid },
              });
              this.$router.go(1);
            }
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      } else {
        this.$store.dispatch("setSelectedWebsite", this.websites[0]);
        if (this.websites.length == 1) {
          this.$store.dispatch("setSelectedWebsiteById", this.websites[0]._id);
          //this.setSelectedWebsite(this.websites[0]._id);
        }
      }
    },
  },

  metaInfo() {
    return {
      title: this.pageTitle,
      /* meta: [
        {
          name: "description",
          content: "My page description",
        },
      ], */
    };
  },
};
</script>

<style>
a {
  text-decoration: none;
}

.router-link-active {
  font-weight: 500;
}
.v-input--switch__thumb {
  border: thin solid rgb(160, 160, 160);
}
.primary--text.v-input--switch__thumb {
  border: thin solid rgba(160, 160, 160, 0) !important;
}
/* .v-text-field {
  font-size: 14px;
} */
/* start text field border change */
.v-text-field--outlined .v-input__control .v-input__slot fieldset {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.v-text-field--outlined:hover .v-input__control .v-input__slot fieldset {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.v-text-field--outlined.v-input--is-focused
  .v-input__control
  .v-input__slot
  fieldset {
  border: 2px solid rgba(0, 0, 0, 0.3) !important;
}
/* end text field border change */

.router-link-active .v-list-item__subtitle {
  font-weight: bold !important;
}

.fade-enter-active {
  transition-property: opacity;
  transition-duration: 0.4s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
.max-w-login-container {
  max-width: 850px;
}
.max-w-requare-access-container {
  max-width: 1000px;
}
.appColor {
  background-color: var(--v-background-base) !important;
}
.appBackgroundColorMain {
  background-color: var(--v-backgroundapp-base) !important;
}

.row {
  margin: 0;
}
.max-w-select-lang-login {
  max-width: 100px !important;
}
.max-w-select-lang-creat-edit-user {
  max-width: 330px !important;
}
.max-w-select-lang {
  max-width: 5rem !important;
}
.custom-overview-row {
  border-bottom: 1px solid #ddd;
}

.custom-overview-row.no-border {
  border-bottom: 0px;
}

.custom-overview-row:last-child {
  border-bottom: 0;
}

.border-top {
  border-top: 1px solid #ddd;
}

.custom-overview-row .input-group:not(.no-styling),
.custom-overview-row h4 {
  margin-left: 15px;
  margin-top: 15px;
  padding-right: 30px;
}

.custom-overview-row-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.custom-overview-row-title {
  text-overflow: ellipsis;
  overflow: hidden;
}

.textInput {
  border: 1px solid #e4e4e4;
  width: 100%;
  min-height: 30px;
  padding: 5px;
  outline: none;
}

.textInput:focus {
  outline: none;
}

.pageElementLanguageSelectRichText {
  max-width: 75px !important;
  padding: 0 !important;
}

.pageElementLanguageSelect {
  max-width: 75px !important;
  padding: 0 !important;
}

.add-item-select {
  max-width: 300px !important;
  float: left;
}

.select-width {
  width: 300px !important;
}

.color-window-onboarding .v-window__prev {
  background: #ffff !important;
  color: #ffff !important;
  margin: 0 0 0 32px;
}
.color-window-onboarding .v-window__next {
  background: #ffff !important;
  color: #ffff !important;
  margin: 0 32px 0 0;
}
.v-data-table-header th {
  white-space: nowrap;
}
/* .language-selector-in-row {
  float: right;
  margin: 10px 0;
} */
.nav-language-select {
  max-width: 100px;
}
.altSelect {
  max-width: 260px !important;
}
.altSelect-lang {
  max-width: 100px !important;
}
.nav-language-select .v-select__slot .v-select__selections input {
  display: none;
}
.nav-language-select .v-select__slot .v-select__selections {
  margin-right: 1rem;
}
#languageSelect:not(language-selector-in-row) {
  padding-right: 30px !important;
  float: left;
  max-width: 30px;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
.file-title-max-lenght .v-alert__content {
  max-width: 100% !important;
}
.full-width {
  width: 100%;
}
.max-lenght-lang-selector {
  max-width: 120px !important;
}
.v-input__prepend-outer {
  margin-right: 16px !important;
}
/* toaster css */
.toaster-color-success {
  background-color: var(--v-primarygreen-base) !important;
}
.toaster-color-error {
  background-color: var(--v-primaryred-base) !important;
}
.toaster-color-info {
  background-color: var(--v-primaryblue-base) !important;
}
.toaster-color-warning {
  background-color: var(--v-primaryorange-base) !important;
}
.toaster-container-class {
  bottom: 8rem !important;
  right:2rem !important;
}
.toaster-layout {
  padding: 8px 16px !important;
  border-radius: 4px;
}
.toaster-text-layout {
  display: flex;
  align-items: center;
}
.icon-font-size {
  font-size: x-large;
}
.mrgin-icon-toaster {
  margin: 10px 16px auto 0px;
}
.textInputWidth {
  width: 50rem !important;
}
.page-label {
  color: #666;
}
.w-100{
  width:100%
}
/* end toaster css */
@media only screen and (max-width: 600px) {
  .language-selector-in-row {
    max-width: 100px;
    margin: 0px 0 30px 0;
  }
}
@media only screen and (max-width: 568px) {
  .edit-page-action-button {
    width: 100%;
  }

  .edit-page-action-button .btn__content {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 8px;
  }

  .edit-page-action-button .btn__content i {
    padding-top: 0px;
    margin-top: -3px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 465px) {
  .dropdown-action-button {
    margin: 0;
    position: relative;
    top: -14px;
  }
}
</style>
