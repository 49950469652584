import request from '../request'
import auth from '../auth'
//initial state
const state = {
    onboardingItems: [],
    onboardingDialog: false,
    onboardingIndex: 0,
    editMode: false,
    drawer: true,
    searchDrawer: false,
    isNavigating: false

}

//getters
const getters = {
    onboardingItems: state => state.onboardingItems,
    hasOnboarding: state => state.onboardingItems.length,
    onboardingDialog: state => state.onboardingDialog,
    onboardingIndex: state => state.onboardingIndex,

    editMode: state => state.editMode,
    drawer: state => state.drawer,
    searchDrawer: state => state.searchDrawer,
    isNavigating: state => state.isNavigating

}

//actions
const actions = {
    setonboadingItems({ commit }, items) {
        commit('setonboadingItems', items)
    },
    setOnboarding({ commit }, bool) {
        commit('setOnboarding', bool)

    },
    setIsNavigating({ commit }, bool) {
        commit('setIsNavigating', bool)
    },
    nextOnboardingIndex({ commit }) {
        commit('nextOnboardingIndex')
    },
    prevOnboardingIndex({ commit }) {
        commit('prevOnboardingIndex')
    },
    getOnboardingItems({ commit }) {
        commit('getOnboardingItems')
    },

    setEditMode({ commit }, bool) {
        commit('setEditMode', bool)

    },
    setDrawer({ commit }, bool) {
        commit('setDrawer', bool)

    },
    setSearchDrawer({ commit }, bool) {
        commit('setSearchDrawer', bool)

    },


}

//mutations
const mutations = {
    setIsNavigating(state, bool) {
        state.isNavigating = bool
    },
    setonboadingItems(state, items) {
        state.onboardingItems = items
    },
    setOnboarding(state, bool) {
        state.onboardingDialog = bool
    },

    getOnboardingItems(state) {
        request.get("/onboardingsteps", null, (res) => {
            if (res.success) {

                state.onboardingItems = res.data
                if (res.data.length && auth.userIsFirstLogin()) {
                    state.onboardingDialog = true
                }
            }
            else {
                const toaster = {
                    message: res.message,
                    timeout: false,
                    type: "ERROR",
                    toastClassName: ["toaster-color-error", "toaster-layout"],
                    bodyClassName: ["toaster-text-layout"],
                    icon: "mdi-alert-octagon",
                };
                this.$store.dispatch("setToaster", toaster);
            }
        });
    },
    nextOnboardingIndex(state) {
        state.onboardingIndex = state.onboardingIndex + 1 === state.hasOnboarding ? 0 : state.onboardingIndex + 1;
    },
    prevOnboardingIndex(state) {
        state.onboardingIndex = state.onboardingIndex - 1 < 0
            ? state.hasOnboarding - 1
            : state.onboardingIndex - 1;
    },
    setEditMode(state, bool) {
        state.editMode = bool
    },
    setDrawer(state, bool) {

        state.drawer = bool
    },
    setSearchDrawer(state, bool) {

        state.searchDrawer = bool
    },

}

export default {
    state,
    getters,
    actions,
    mutations
}
