import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import auth from './auth'
import helpers from './helpers'
import request from './request'
import store from "./store"
import vuexI18n from 'vuex-i18n';
import 'vue-prism-editor/dist/prismeditor.min.css';
import validation from "./validation";
import Toast, { POSITION } from "vue-toastification";



//vuetify
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import "vue-toastification/dist/index.css";

//other components
import App from './components/App'

const toastOptions = {
  position: POSITION.BOTTOM_RIGHT,
  containerClassName: "toaster-container-class",
  transition: "Vue-Toastification__fade",
};

Vue.use(VueRouter)
Vue.use(VueMeta)
Vue.use(vuexI18n.plugin, store)
Vue.use(Vuetify);
Vue.use(Toast, toastOptions);

//vue components
Vue.component('addButton', () => import(/* webpackChunkName: "addButton" */ '@/components/shared/AddButton.vue'));
Vue.component('addRemovePageElement', () => import(/* webpackChunkName: "addRemovePageElement" */ '@/components/shared/AddRemovePageElement.vue'));
Vue.component('backButton', () => import(/* webpackChunkName: "backButton" */ '@/components/shared/BackButton.vue'));
Vue.component('cancelConfirmButtons', () => import(/* webpackChunkName: "cancelConfirmButtons" */ '@/components/shared/CancelConfirmButtons.vue'));
Vue.component('languageSelector', () => import(/* webpackChunkName: "languageSelector" */ '@/components/shared/LanguageSelector.vue'));
Vue.component('pageElement', () => import(/* webpackChunkName: "pageElement" */ '@/components/shared/PageElement.vue'));
Vue.component('pageNameLanguageSelector', () => import(/* webpackChunkName: "pageNameLanguageSelector" */ '@/components/shared/PageNameLanguageSelector.vue'));
Vue.component('configLanguageSelector', () => import(/* webpackChunkName: "configLanguageSelector" */ '@/components/shared/ConfigLanguageSelector.vue'));
Vue.component('preloader', () => import(/* webpackChunkName: "preloader" */ '@/components/shared/Preloader.vue'));
Vue.component('login', () => import(/* webpackChunkName: "login" */ '@/components/login/Login.vue'));
Vue.component('date-picker', () => import(/* webpackChunkName: "date-picker" */ '@/components/shared/DatePicker.vue'));
Vue.component('time-picker-component', () => import(/* webpackChunkName: "time-time-picker-component" */ '@/components/shared/TimePicker.vue'));
Vue.component('filterComponent', () => import(/* webpackChunkName: "filterComponent" */ '@/components/shared/LeftFilter.vue'));
Vue.component('errorHandeling', () => import(/* webpackChunkName: "errorHandeling" */ '@/components/error/ErrorHandeling.vue'));
Vue.component('dragAndDrop', () => import(/* webpackChunkName: "dragAndDrop" */ '@/components/shared/DragAndDrop.vue'));
Vue.component('onboarding-cards', () => import(/* webpackChunkName: "onboarding-cards" */ '@/components/onboarding/OnboardingCards.vue'));
Vue.component('mailchimp-integration', () => import(/* webpackChunkName: "mailchimp-integration" */ '@/components/integrations/MailchimpIntegration.vue'));
Vue.component('sidebar-left', () => import(/* webpackChunkName: "sidebar-left" */ './components/shared/app/SidebarLeft.vue'));
Vue.component('sidebar-right', () => import(/* webpackChunkName: "sidebar-right" */ './components/shared/app/SidebarRight.vue'));
Vue.component('app-header', () => import(/* webpackChunkName: "app-header" */ './components/shared/app/Header.vue'));
Vue.component('app-footer', () => import(/* webpackChunkName: "app-footer" */ './components/shared/app/Footer.vue'));
Vue.component('app-onboarding', () => import(/* webpackChunkName: "app-onboarding" */ './components/shared/app/Onboarding.vue'));
Vue.component('app-reportingerror', () => import(/* webpackChunkName: "app-reportingerror" */ './components/shared/app/ReportError.vue'));
Vue.component('app-versionupdatesnackbars', () => import(/* webpackChunkName: "app-versionupdatesnackbars" */ './components/shared/app/VersionUpdateSnackbars.vue'));
Vue.component('repeating-content', () => import(/* webpackChunkName: "repeating-content" */ './components/page/RepeatingContent.vue'));
Vue.component('editmode-switch', () => import(/* webpackChunkName: "editmode-switch" */ './components/shared/EditModeSwitch.vue'));
Vue.component('page-element-text', () => import(/* webpackChunkName: "page-element-text" */ './components/shared/types/pageElementText.vue'));
Vue.component('page-element-number', () => import(/* webpackChunkName: "page-element-number" */ './components/shared/types/pageElementNumber.vue'));
Vue.component('page-element-richtext', () => import(/* webpackChunkName: "page-element-richtext" */ './components/shared/types/pageElementRichtext.vue'));
Vue.component('page-element-boolean', () => import(/* webpackChunkName: "page-element-boolean" */ './components/shared/types/pageElementBoolean.vue'));
Vue.component('page-element-datepicker', () => import(/* webpackChunkName: "page-element-datepicker" */ './components/shared/types/pageElementDatepicker.vue'));
Vue.component('page-element-timepicker', () => import(/* webpackChunkName: "page-element-timepicker" */ './components/shared/types/pageElementTimepicker.vue'));
Vue.component('page-element-dropdown', () => import(/* webpackChunkName: "page-element-dropdown" */ './components/shared/types/pageElementDropdown.vue'));
Vue.component('page-element-content', () => import(/* webpackChunkName: "page-element-content" */ './components/shared/types/pageElementContent.vue'));
Vue.component('page-element-image', () => import(/* webpackChunkName: "page-element-image" */ './components/shared/types/pageElementImage.vue'));
Vue.component('page-element-croppedimage', () => import(/* webpackChunkName: "page-element-croppedimage" */ './components/shared/types/pageElementCroppedimage.vue'));
Vue.component('page-element-gallery', () => import(/* webpackChunkName: "page-element-gallery" */ './components/shared/types/pageElementGallery.vue'));
Vue.component('page-element-files', () => import(/* webpackChunkName: "page-element-files" */ './components/shared/types/pageElementFiles.vue'));
Vue.component('page-element-custom', () => import(/* webpackChunkName: "page-element-custom" */ './components/shared/types/pageElementCustom.vue'));
Vue.component('page-element-colorpicker', () => import(/* webpackChunkName: "page-element-colorpicker" */ './components/shared/types/pageElementColorpicker.vue'));
Vue.component('page-element-codefield', () => import(/* webpackChunkName: "page-element-codefield" */ './components/shared/types/pageElementCodefield.vue'));
Vue.component('page-element-url', () => import(/* webpackChunkName: "page-element-url" */ './components/shared/types/pageElementUrl.vue'));
Vue.component('page-element-qrcode', () => import(/* webpackChunkName: "page-element-qrcode" */ './components/shared/types/pageElementQRcode.vue'));
Vue.component('page-element-htmlobject', () => import(/* webpackChunkName: "page-element-htmlobject" */ './components/shared/types/pageElementHTMLObject.vue'));
Vue.component('edit-page-details-settings', () => import(/* webpackChunkName: "edit-page-details-settings" */ './components/page/EditPageDetailsSettings'));
Vue.component('edit-page-details-linkedPages', () => import(/* webpackChunkName: "edit-page-details-linkedPages" */ './components/page/EditPageDetailsLinkedPages'));
Vue.component('edit-page-details-urlsettings', () => import(/* webpackChunkName: "edit-page-details-urlsettings" */ './components/page/EditPageDetailsURLSettings'));
Vue.component('edit-page-details-seo', () => import(/* webpackChunkName: "edit-page-details-seo" */ './components/page/EditPageDetailsSEO'));
Vue.component('edit-page-details-icon', () => import(/* webpackChunkName: "edit-page-details-icon" */ './components/page/EditPageDetailsIcon'));
Vue.component('non-repeating-content', () => import(/* webpackChunkName: "non-repeating-content" */ './components/page/NonRepeatingContent'));
Vue.component('repeating-content', () => import(/* webpackChunkName: "repeating-content" */ './components/page/RepeatingContent'));
Vue.component('create-edit-user-roles', () => import(/* webpackChunkName: "create-edit-user-roles" */ './components/user/CreateEditUserRoles'));
Vue.component('edit-read-only-item-switch', () => import(/* webpackChunkName: "edit-read-only-item-switch" */ './components/shared/admin-components/editReadOnlyItemSwitch'));

//vue files for global use
Vue.prototype.$auth = auth;
Vue.prototype.$helpers = helpers;
Vue.prototype.$request = request;
Vue.prototype.$validation = validation;



const router = new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    { path: '/' },
    {
      path: '/routenotauthorized',
      name: 'routeNotAuthorized',
      component: () => import(/* webpackChunkName: "routeNotAuthorized"*/ "@/components/RouteNotAuthorized.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard"*/ "@/components/Dashboard.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/websitesoverview',
      name: 'websites',
      component: () => import(/* webpackChunkName: "websites"*/ "@/components/website/ManageWebsites.vue"),
      meta: { adminRoute: true }
    },
    {
      path: '/translations',
      name: 'translations',
      component: () => import(/* webpackChunkName: "translations"*/ "@/components/website/Translations.vue"),
      meta: { adminRoute: true }
    },
    {
      path: '/login',
      name: "login",
      component: () => import(/* webpackChunkName: "login"*/ "@/components/login/Login.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/websitesoverview/deletewebsite/:id',
      name: 'deleteWebsite',
      component: () => import(/* webpackChunkName: "deleteWebsite"*/ "@/components/website/DeleteWebsite.vue"),
      meta: { adminRoute: true }
    },
    {
      path: '/websitesoverview/createeditwebsite',
      name: 'createNewWebsite',
      component: () => import(/* webpackChunkName: "createNewWebsite"*/ "@/components/website/CreateEditWebsite.vue"),
      meta: { adminRoute: true }
    },
    {
      path: '/websitesoverview/createeditwebsite/:id',
      name: 'createEditWebsite',
      component: () => import(/* webpackChunkName: "createNewWebsite"*/ "@/components/website/CreateEditWebsite.vue"),
      meta: { adminRoute: true }
    },
    {
      path: '/usersoverview',
      name: 'manageUsers',
      component: () => import(/* webpackChunkName: "manageUsers"*/ "@/components/user/ManageUsers.vue"),
      meta: { adminRoute: true, userManagerRoute: true }
    },
    {
      path: '/usersoverview/deleteuser/:id',
      name: 'deleteUser',
      component: () => import(/* webpackChunkName: "deleteUser"*/ "@/components/user/DeleteUser.vue"),
      meta: { adminRoute: true, userManagerRoute: true }
    },
    {
      path: '/usersoverview/createedituser',
      component: () => import(/* webpackChunkName: "createNewUser"*/ "@/components/user/CreateEditUser.vue"),
      name: 'createNewUser',
      meta: { adminRoute: true, userManagerRoute: true }
    },
    {
      path: '/usersoverview/createedituser/:id',
      name: 'createEditUser',
      component: () => import(/* webpackChunkName: "createEditUser"*/ "@/components/user/CreateEditUser.vue"),
      meta: { adminRoute: true, userManagerRoute: true }
    },
    {
      path: '/usersoverview/createedituser/copyuser/:id',
      name: 'createEditCopyUser',
      component: () => import(/* webpackChunkName: "createEditCopyUser"*/ "@/components/user/CreateEditUser.vue"),
      meta: { adminRoute: true, userManagerRoute: true }
    },
    {
      path: '/page/editpagedetails/:name/:websiteId/:id',
      name: 'editPageDetails',
      component: () => import(/* webpackChunkName: "editPageDetails"*/ "@/components/page/EditPageDetails.vue"),
      meta: { adminRoute: true }
    },
    {
      path: '/page/editpage/:id',
      name: 'editPage',
      component: () => import(/* webpackChunkName: "editPage"*/ "@/components/page/EditPage.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/page/editpage/:id/addedititem/',
      name: 'editPageAddItem',
      component: () => import(/* webpackChunkName: "editPageAddItem"*/ "@/components/page/EditPageItem.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/page/editpage/:id/newcopy/:itemId',
      name: 'editPageAddCopyItem',
      component: () => import(/* webpackChunkName: "editPageAddCopyItem"*/ "@/components/page/EditPageItem.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/page/editpage/:id/detail/:itemId',
      name: 'editPageViewItem',
      component: () => import(/* webpackChunkName: "editPageViewItem"*/ "@/components/page/EditPageItem.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/page/editpage/:id/addedititem/:itemId',
      name: 'editPageAddEditItem',
      component: () => import(/* webpackChunkName: "editPageAddEditItem"*/ "@/components/page/EditPageItem.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/page/editpage/:id/deletepageitem/:itemId',
      name: 'deletePageItem',
      component: () => import(/* webpackChunkName: "deletePageItem"*/ "@/components/page/DeletePageItem.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/forgotpassword',
      name: 'forgotPassword',
      component: () => import(/* webpackChunkName: "forgotPassword"*/ "@/components/login/ForgotPassword.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/requestaccess',
      name: 'requestaccess',
      component: () => import(/* webpackChunkName: "requestaccess"*/ "@/components/login/RequestAccess.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/resetpassword/:hash',
      name: 'resetPassword',
      component: () => import(/* webpackChunkName: "ResetPassword"*/ "@/components/login/ResetPassword.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/activateaccount/:hash',
      name: 'activateAccount',
      component: () => import(/* webpackChunkName: "activateAccount"*/ "@/components/login/ActivateAccount.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import(/* webpackChunkName: "settings"*/ "@/components/website/Settings.vue"),
      meta: { adminRoute: true }
    },
    {
      path: '/pagenotfound',
      name: 'pagenotfound',
      component: () => import(/* webpackChunkName: "pagenotfound"*/ "@/components/page/NotFoundPage.vue"),
      meta: { adminRoute: false }
    },
    {
      path: '*',
      name: 'notFoundPage',
      component: () => import(/* webpackChunkName: "pagenotfound"*/ "@/components/page/NotFoundPage.vue"),
      meta: { adminRoute: false }
    }
  ],
  errorHandler: () => {
    // Handle the error gracefully
  }

})

const requests = [
  new Request("/config.json", { cache: "reload" }),
  new Request("/translationsNl.json", { cache: "reload" }),
  new Request("/translationsFr.json", { cache: "reload" }),
  new Request("/translationsEn.json", { cache: "reload" })
];

Promise.all(requests.map(request => fetch(request)))
  .then(allResponses => {
    return Promise.all(allResponses.map(res => res.json()));
  })
  .then(allResponses => {
    const [config, translationsNl, translationsFr, translationsEn] = allResponses;
    if (config) {
      Vue.prototype.$config = config
      const vuetify = new Vuetify({
        theme: {
          themes: {
            light: {
              primary: config.primaryColor,
              secondary: config.secondaryColor,
              background: config.backgroundColor,
              backgroundapp: config.backgroundAppColor,
              activemenucolor: config.activeMenuColor,
              nonprimarybuttoncolor: config.nonPrimaryButtons,
              primaryred: config.primaryRed,
              primaryblue: config.primaryBlue,
              primaryorange: config.primaryOrange,
              primarygreen: config.primaryGreen,
            },
          },
          options: {
            customProperties: true,
          },

        },
        icons: {
          iconfont: 'mdi',
        }
      });
      request.get('/validatesession', {}, (res) => {
        if (res.success) {
          auth.setUser(res.data)
        } else {
          auth.deleteUser()
        }

        router.beforeEach(async (to, from, next) => {
          if (store.getters.isNavigating) {
            // If navigation is already in progress, abort this navigation
            next(false)
          }
          const publicPages = ['/login', '/forgotpassword', '/resetpassword', '/activateaccount', '/requestaccess'];
          const authRequired = !publicPages.includes(to.path);
          const isLoggedIn = auth.loggedIn();
          const isAdmin = auth.userIsAdministrator();
          const isUserManager = auth.userIsUserManager();
          const isOnAdminRoute = to.meta.adminRoute;
          const isOnUserManagerRoute = to.meta.userManagerRoute;
          store.dispatch('setIsNavigating', true)
          if (!isLoggedIn) {
            // Allow access to reset password, account activation and forgot password pages
            if (to.path.includes('/resetpassword') || to.path.includes('/activateaccount') || to.path.includes('/forgotpassword') || to.path.includes('/requestaccess')) {
              next();
            } else if (to.path === '/' || authRequired) {
              // Redirect to login if not logged in and attempting to access a protected page
              next('/login');
            }
          }
          else if (isLoggedIn) {
            if (isOnAdminRoute || isOnUserManagerRoute) {
              // Redirect to not authorized page if user is not admin or user manager
              if ((isOnAdminRoute && isAdmin) || (isOnUserManagerRoute && isUserManager)) {
                next();
              } else {
                next('/routenotauthorized');
              }
            } else {
              // Redirect to dashboard if already logged in and attempting to access one of the public pages
              if (publicPages.includes(to.path)) {
                next('/dashboard');
              }
              else if (to.path === '/pagenotfound') {
                // Allow access to page not found
                next();
              }
              else if (from.name == null) {
                next()
              }
              else {
                next()
              }
            }
          }

          // Allow navigation to next page if none of the previous cases match
          next();
        });
        router.afterEach(() => {
          store.dispatch('setIsNavigating', false)
        })
        Vue.config.errorHandler = function (err, vm, info) {
          const error = {
            err: err,
            vm: vm,
            info: info,
          }
          store.dispatch("setErrorMain", error);

          // handle error
          // `info` is a Vue-specific error info, e.g. which lifecycle hook
          // the error was found in. Only available in 2.2.0+

        }

        new Vue({
          el: '#app',
          router,
          vuetify,
          store,
          render: h => h(App),
        })
      })


    }
    if (translationsNl) {
      Vue.i18n.add('Nl', translationsNl);
    }
    if (translationsFr) {
      Vue.i18n.add('Fr', translationsFr);
    }
    if (translationsEn) {
      Vue.i18n.add('En', translationsEn);
    }
    if (translationsNl ||
      translationsFr ||
      translationsEn) {

      try {
        const currentLanguage = (navigator.language || navigator.userLanguage).split("-")[0]

        if (auth.userLanguage() != "") {
          Vue.i18n.set(auth.userLanguage())
        } else if (currentLanguage == "Fr") {
          Vue.i18n.set('Fr')
        }
        else if (currentLanguage == "En") {
          Vue.i18n.set('En')
        }
        else {
          Vue.i18n.set('Nl')
        }
      }
      catch (err) {
        Vue.i18n.set('Nl')
      }
    }
  })





