import Vue from "vue";
import { TYPE } from "vue-toastification";

//initial state
const state = {
    toaster: null,
}

//getters
const getters = {

}

//actions
const actions = {
    setToaster({ commit }, toaster) {
        commit('setToaster', toaster)
    },

}
//mutations
const mutations = {
    setToaster(state, toaster) {
        if (toaster.message == "") {
            toaster.message = Vue.i18n.translate('Something went wrong!')
        }
        Vue.$toast(
            toaster.message, {
            timeout: toaster.timeouts, // can be false, this means it has no timout
            type: TYPE[toaster.type], // can be (uppercase) "success", "error", "default", "info" and "warning",
            toastClassName: toaster.toastClassName,
            bodyClassName: toaster.bodyClassName,
            icon: {
                iconClass: `mdi ${toaster.icon} icon-font-size mrgin-icon-toaster`,
                iconTag: 'span',

            },

        });


    },

}

export default {
    state,
    getters,
    actions,
    mutations
}
